"use strict";

exports.__esModule = true;
exports["default"] = void 0;

var _recaptchaWrapper = _interopRequireDefault(require("./recaptcha-wrapper"));

var _recaptcha = _interopRequireDefault(require("./recaptcha"));

exports.ReCAPTCHA = _recaptcha["default"];

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = _recaptchaWrapper["default"];
exports["default"] = _default;